import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAuthProvider } from '../../Providers'
import { BecameEmployeeWarning } from '../BecameEmployeeWarning'
import { JWTDebugger } from '../JWTDebugger'
import { RevertInterfaceBeta } from '../RevertInterfaceBeta'
import { ThemeSwitcher } from '../ThemeSwitcher'
import { NavigationItem } from './components'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const TopBar: React.FC<IProps> = ({ brandIcon, menuIcon, navigationItems }): JSX.Element => {
  const { t } = useTranslation()
  const { loggedIn } = useAuthProvider()
  const isProduction = import.meta.env.REACT_APP_ENVIRONMENT === 'production'

  return (
    <nav className={Styles.topBar} aria-label={t('Topbar navigation')} role="navigation">
      <div className={Styles.notificationContainer}>
        <BecameEmployeeWarning />
      </div>

      <div className={Styles.topBarContent}>
        <div className={Styles.wrapper}>
          {loggedIn ? <div className={Styles.menu}>{menuIcon}</div> : null}

          {brandIcon}
        </div>

        <div className={Styles.navigationItems}>
          {!isProduction && <ThemeSwitcher />}

          <RevertInterfaceBeta />

          {!isProduction && <JWTDebugger />}

          {navigationItems}
        </div>
      </div>
    </nav>
  )
}

export const TopBarNavigationItem = NavigationItem
