import { ROUTE_NAMES, TRoutes } from './interfaces'

export const routes: TRoutes = {
  [ROUTE_NAMES.APPROVALS]: `/approvals`,
  [ROUTE_NAMES.APPROVALS_SUBORDINATE]: `/approvals/:id`,
  [ROUTE_NAMES.APPROVALS_SUBORDINATE_DECLARATION]: `/approvals/:id/declaration`,
  [ROUTE_NAMES.DASHBOARD]: '/',
  [ROUTE_NAMES.CARDS_AND_SERVICES]: '/cards_and_services',
  [ROUTE_NAMES.CARDS_AND_SERVICES_FEATURE_DETAILS]: '/cards_and_services/feature/:id',
  [ROUTE_NAMES.CARDS_AND_SERVICES_CARD_DETAILS]: '/cards_and_services/card/:id',
  [ROUTE_NAMES.CARDS_AND_SERVICES_CARD_ORDER_DETAILS]: '/cards_and_services/card_order/:id',
  [ROUTE_NAMES.CARDS_AND_SERVICES_SERVICE_DETAILS]: '/cards_and_services/service/:id',
  [ROUTE_NAMES.EMISSION]: `/emission`,
  [ROUTE_NAMES.EMISSION_BETA]: `/emission_beta`,
  [ROUTE_NAMES.ERROR]: `/error`,
  [ROUTE_NAMES.FAVOURITES]: `/favourites`,
  [ROUTE_NAMES.FINANCES]: `/finances`,
  [ROUTE_NAMES.HELP]: '/help',
  [ROUTE_NAMES.LOGOUT]: `/`,
  [ROUTE_NAMES.MILEAGES]: `/mileages`,
  [ROUTE_NAMES.MILEAGES_EDIT]: `/mileages/:mileageId/edit`,
  [ROUTE_NAMES.MILEAGES_NEW]: `/mileages/new`,
  [ROUTE_NAMES.MOBILITY_EVENT_DETAILS]: `/mobility_events/:id`,
  [ROUTE_NAMES.MOBILITY_EVENTS]: `/mobility_events`,
  [ROUTE_NAMES.PAYMENTS]: `/payments`,
  [ROUTE_NAMES.PAYMENTS_INVOICES]: `/payments/invoices`,
  [ROUTE_NAMES.PAYMENTS_MANDATES]: `/payments/mandates`,
  [ROUTE_NAMES.PRIVACY]: '/privacy',
  [ROUTE_NAMES.PRODUCT_DETAILS_MILEAGE]: `/products/:id/mileage`,
  [ROUTE_NAMES.PRODUCT_DETAILS]: `/products/:id`,
  [ROUTE_NAMES.PRODUCTS]: `/products`,
  [ROUTE_NAMES.PROFILE]: `/profile`,
  [ROUTE_NAMES.PROFILE_SETTINGS]: `/profile/settings`,
  [ROUTE_NAMES.PROFILE_CONDITIONS]: `/profile/settings/all_conditions`,
  [ROUTE_NAMES.PROFILE_INFORMATION]: `/profile/information`,
  [ROUTE_NAMES.PROFILE_QR]: `/profile/qr`,
  [ROUTE_NAMES.REAUTHORIZE]: `/reauthorize`,
  [ROUTE_NAMES.REDIRECTING]: `/redirecting`,
  [ROUTE_NAMES.REUPLOAD_PHOTO]: '/reupload_photo/:id',
  [ROUTE_NAMES.ROUTE_PLANNER]: `/route_planner`,
  [ROUTE_NAMES.SETTLEMENTS]: '/settlements',
  [ROUTE_NAMES.SPLIT_BILLING_PAYMENTS]: '/split_billing_payments',
  [ROUTE_NAMES.SPLIT_BILLING_PAYMENTS_INVOICES]: `/split_billing_payments/invoices`,
  [ROUTE_NAMES.SPLIT_BILLING_PAYMENTS_MANDATES]: `/split_billing_payments/mandates`,
  [ROUTE_NAMES.SUBSTITUTE_MANAGER]: `/substitute_manager`,
  [ROUTE_NAMES.TRANSPORT]: `/transport`,
  [ROUTE_NAMES.TRANSPORT_DONKEY_REPUBLIC_DETAILS]: `/transport/donkey_republic/:id`,
  [ROUTE_NAMES.TRANSPORT_RESERVATION]: `/transport/reservation`,
  [ROUTE_NAMES.TRANSPORT_SHARED_VEHICLE_OVERVIEW]: `/transport/shared_vehicle/overview`,
  [ROUTE_NAMES.TRANSPORT_SHARED_VEHICLE_OVERVIEW_DETAILS]: `/transport/shared_vehicle/overview/details`,
  [ROUTE_NAMES.TRANSPORT_SHARED_VEHICLE_DETAILS]: `/transport/shared_vehicle/:id`,
  [ROUTE_NAMES.TRANSPORT_PRE_TAXI_DETAILS]: `/transport/taxi`,
  [ROUTE_NAMES.TRANSPORT_POST_TAXI_DETAILS]: `/transport/taxi/:id`,
  [ROUTE_NAMES.TRANSPORT_TRANZER_DETAILS]: `/transport/tranzer/:id`,
  [ROUTE_NAMES.WELCOME]: `/welcome`,
  [ROUTE_NAMES.WELCOME_PERSONAL_INFORMATION]: `/welcome/personal_information`,
  [ROUTE_NAMES.WELCOME_WORK_INFORMATION]: `/welcome/work_information`,
  [ROUTE_NAMES.WELCOME_TERMS_AND_CONDITIONS]: `/welcome/terms_and_conditions`,
  [ROUTE_NAMES.WELCOME_DONE]: `/welcome/done`,
  [ROUTE_NAMES.WRPM]: `/wrpm`,
}
