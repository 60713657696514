export interface IProps {
  showOnboarding: boolean
}

export enum ROUTE_NAMES {
  APPROVALS,
  APPROVALS_SUBORDINATE,
  APPROVALS_SUBORDINATE_DECLARATION,
  CARDS_AND_SERVICES,
  CARDS_AND_SERVICES_FEATURE_DETAILS,
  CARDS_AND_SERVICES_CARD_DETAILS,
  CARDS_AND_SERVICES_CARD_ORDER_DETAILS,
  CARDS_AND_SERVICES_SERVICE_DETAILS,
  DASHBOARD,
  EMISSION,
  EMISSION_BETA,
  ERROR,
  FAVOURITES,
  FINANCES,
  HELP,
  LOGOUT,
  MILEAGES,
  MILEAGES_EDIT,
  MILEAGES_NEW,
  MOBILITY_EVENTS,
  MOBILITY_EVENT_DETAILS,
  PAYMENTS,
  PAYMENTS_INVOICES,
  PAYMENTS_MANDATES,
  PRIVACY,
  PRODUCT_DETAILS,
  PRODUCT_DETAILS_MILEAGE,
  PRODUCTS,
  PROFILE,
  PROFILE_SETTINGS,
  PROFILE_CONDITIONS,
  PROFILE_INFORMATION,
  PROFILE_QR,
  REDIRECTING,
  REUPLOAD_PHOTO,
  ROUTE_PLANNER,
  SETTLEMENTS,
  SPLIT_BILLING_PAYMENTS,
  SPLIT_BILLING_PAYMENTS_INVOICES,
  SPLIT_BILLING_PAYMENTS_MANDATES,
  SUBSTITUTE_MANAGER,
  TRANSPORT,
  TRANSPORT_DONKEY_REPUBLIC_DETAILS,
  TRANSPORT_RESERVATION,
  TRANSPORT_SHARED_VEHICLE_OVERVIEW,
  TRANSPORT_SHARED_VEHICLE_OVERVIEW_DETAILS,
  TRANSPORT_SHARED_VEHICLE_DETAILS,
  TRANSPORT_POST_TAXI_DETAILS,
  TRANSPORT_PRE_TAXI_DETAILS,
  TRANSPORT_TRANZER_DETAILS,
  WELCOME,
  WELCOME_PERSONAL_INFORMATION,
  WELCOME_WORK_INFORMATION,
  WELCOME_TERMS_AND_CONDITIONS,
  WELCOME_DONE,
  WRPM,
  REAUTHORIZE,
}

export type TRoutes = {
  [route in ROUTE_NAMES]: string
}
