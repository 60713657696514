import { format } from 'date-fns'

import { TLocale } from '../../sharedInterfaces'
import { localeForDateFn, localeForICU } from './helpers'
import { ICurrentDateProps } from './interfaces'

// Safari has a bug that it cannot handle date strings like `new Date("2021-09-10 13:35:07 +0200")`
// replacing it with `new Date("2021/09/10 13:35:07 +0200")` works fine...
export const parseDate = (dateString: string): Date => new Date(dateString.replace(/-/g, '/'))

export const formatDate = (date: Date | string, options: ICurrentDateProps): string => {
  const parsedDate = typeof date === 'string' ? parseDate(date) : date

  const {
    locale,
    humanized = false,
    formatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
  } = options

  const convertedLocale = localeForICU(locale)

  if (!humanized) return new Intl.DateTimeFormat(convertedLocale).format(parsedDate)

  return parsedDate.toLocaleDateString(convertedLocale, formatOptions)
}

export const currentDate = (options: ICurrentDateProps): string => {
  return formatDate(new Date(), options)
}

export const formatDateToISOString = (date: Date): string => format(date, 'yyyy-MM-dd')

export const formatDateTimeToISOString = (dateString: string): string | null => {
  if (!dateString) return null

  const convertToISO = dateString
    .trim()
    .replace(' ', 'T') // Replace the space before the time by 'T'
    .replace(' +', '+') // Replace space before the timezone

  const parsedDate = new Date(convertToISO)

  return parsedDate.toISOString()
}

export const formatDateString = (date: string | Date, formatString: string, locale: TLocale): string => {
  const dateString = typeof date === 'object' ? date.toString() : date
  return format(parseDate(dateString), formatString, { locale: localeForDateFn(locale) })
}

/**
 * @param number
 * @returns Returns the given number with a leading zero if needed.
 */
export const getLeadingZeroTimeUnit = (number: number): string => {
  return number < 10 ? `0${number}` : `${number}`
}
