export type TNavigateDirection = 1 | -1

export type TDateRange = {
  from: Date
  to: Date
}

export enum IPeriodSelectorMode {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
  CUSTOM = 'custom',
}

export type TPeriodSelectorData = {
  period: TDateRange
  mode: IPeriodSelectorMode
}

export interface IProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  minDate: Date
  maxDate?: Date
  optionalMode?: IPeriodSelectorMode.YEAR | IPeriodSelectorMode.CUSTOM
  value?: TPeriodSelectorData
  onChange: (_: TPeriodSelectorData) => void
}
