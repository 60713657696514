import React from 'react'

import { MaybeRenderWithHintHOC } from '../FormComponents/Lib/MaybeRenderWithHintHOC'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

const TypographyComponent: React.FC<IProps> = ({
  variant,
  element,
  className,
  children,
  onClick,
  noMargin = false,
  ...props
}): JSX.Element => {
  const variantsMapping: { [key: string]: keyof JSX.IntrinsicElements } = {
    'h1-xx-large': 'h1',
    'h1-x-large': 'h1',
    'h1-large': 'h1',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    'body-1': 'p',
    'body-2': 'p',
    'body-3': 'p',
    'body-4': 'p',
    'body-5': 'p',
  }

  const Component = element || variantsMapping[variant]

  const getClassNames = (): string => {
    const classNames: Array<string> = [Styles.typography, Styles[variant]]

    if (className) classNames.push(className)
    if (noMargin) classNames.push(Styles.noMargin)

    return classNames.join(' ')
  }

  return (
    <Component className={getClassNames()} {...props} onClick={onClick}>
      {children}
    </Component>
  )
}

export const Typography = MaybeRenderWithHintHOC(TypographyComponent)
