import React from 'react'

import { Icon, Typography } from '#/Components'

import { IProps } from './interface'
import Styles from './styles.module.scss'

export const StatusLabel: React.FC<IProps> = ({
  label,
  iconName,
  variant,
  testid,
  className,
  ...otherProps
}): JSX.Element => {
  return (
    <div
      className={`${Styles.statusLabel} ${Styles[variant]} ${className || ''}`}
      data-testid={testid || null}
      {...otherProps}
    >
      <Typography variant="h4" element="p" noMargin>
        {label}
      </Typography>

      {iconName && <Icon name={iconName} width={12} height={12} />}
    </div>
  )
}
