import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Icon, TaskSidebar, TopBar } from '../../../../Components'
import { LazyImage } from '../../../../Components/LazyImage'
import { ProfileSidebar } from '../../../../Components/ProfileSidebar'
import { SidebarCollapsedContext, ThemeContext, useAuthProvider } from '../../../../Providers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const MainTopBar: React.FC<IProps> = ({ hideNavigation }): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { loggedIn } = useAuthProvider()
  const { sidebarCollapsed, toggleSidebarCollapsed } = useContext(SidebarCollapsedContext)
  const { currentTheme } = useContext(ThemeContext)

  const handleMenuClick = (): void => toggleSidebarCollapsed()

  const renderItems = (): JSX.Element => {
    return (
      <div style={{ display: 'flex' }}>
        {loggedIn && (
          <React.Fragment>
            <ProfileSidebar />
            <TaskSidebar />
          </React.Fragment>
        )}
      </div>
    )
  }

  const renderMenuIcon = (): JSX.Element => {
    return (
      /* eslint-disable-next-line reisbalans-rules/prevent-vanilla-html-elements */
      <button
        aria-expanded={sidebarCollapsed ? 'false' : 'true'}
        aria-label={sidebarCollapsed ? t('Expand menu') : t('Collapse menu')}
        className={Styles.iconContainer}
        onClick={handleMenuClick}
        type="button"
        data-testid="menu-icon"
      >
        <Icon name="menu" className={Styles.menuIcon} width={26} height={26} />
      </button>
    )
  }

  const renderLogo = (): JSX.Element => {
    return (
      <div
        className={Styles.logoWrapper}
        data-testid="brand-logo"
        role="button"
        tabIndex={0}
        onClick={(): void => navigate('/')}
      >
        {currentTheme.icon ? (
          <LazyImage alt="Brand logo" src={currentTheme.icon.uri || ''} width="100%" height="100%" />
        ) : (
          <Icon name="house" />
        )}
      </div>
    )
  }

  if (hideNavigation) return <div data-testid="empty-topbar" className={Styles.emptyTopBar} />

  return <TopBar brandIcon={renderLogo()} menuIcon={renderMenuIcon()} navigationItems={renderItems()} />
}
