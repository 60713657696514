import '#lib/i18n'
// Any css imports here should also be added to the Decorator.jsx in ~/.storybook
import 'react-simple-flex-grid/lib/main.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import './toast_override.scss'
// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import './modal_override.scss'

import { ApolloProvider } from '@apollo/client'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import React, { memo } from 'react'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { prefixer } from 'stylis'

import { AuthProvider, MediaQueriesProvider, ThemeProvider, useAuthProvider } from '#/Providers'
import { ROUTE_NAMES } from '#layouts/Unauthorized/interfaces'
import { routes } from '#layouts/Unauthorized/routes'
import { authorizedClient, unauthorizedClient as getUnauthorizedCLient } from '#lib/apolloClient'
import { flushAccessToken, getToken } from '#lib/jwtHelper'
import { TLocale } from '#lib/sharedInterfaces'

import { Icon } from './Components'
import { SideDrawerContainer } from './Components/SideDrawerContainer'
import { AuthorizedLayout, UnauthorizedLayout } from './Layouts'
import Styles from './styles.module.scss'

const LoginOrMain = memo((): JSX.Element => {
  const { loggedIn } = useAuthProvider()

  return loggedIn ? <AuthorizedLayout /> : <UnauthorizedLayout />
})

const forceLogout = (): void => {
  const { search, pathname } = window.location
  const refreshToken = new URLSearchParams(search).get('refresh_token') || ''

  if (pathname === routes[ROUTE_NAMES.REAUTHORIZE] && refreshToken) flushAccessToken()
}

const customPlugin = (): void => {}

const myCache = createCache({
  key: 'react-select-emotion',
  nonce: 'react-select-emotion-nonce',
  stylisPlugins: [
    customPlugin,
    // has to be included manually when customizing `stylisPlugins` if you want to have vendor prefixes added automatically
    prefixer,
  ],
})

export const Wrapper: React.FC = (): JSX.Element => {
  const { loggedIn, reauthorizeWithRefreshToken } = useAuthProvider()
  const { link } = createNetworkStatusNotifier()
  const { i18n } = useTranslation()
  const locale = i18n.language as TLocale
  const unauthorizedClient = getUnauthorizedCLient(locale)

  const renderCloseButton = (): JSX.Element => {
    return <Icon width={10} height={10} className={Styles.toastCloseIcon} name="cross" />
  }

  return (
    <ThemeProvider client={unauthorizedClient}>
      <ApolloProvider
        client={loggedIn ? authorizedClient(link, locale, reauthorizeWithRefreshToken) : unauthorizedClient}
      >
        <CacheProvider value={myCache}>
          <MediaQueriesProvider>
            <ToastContainer
              position="bottom-left"
              icon={false}
              closeButton={renderCloseButton}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
            />

            <SideDrawerContainer id="sideDrawerContainer" />

            <LoginOrMain />
          </MediaQueriesProvider>
        </CacheProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}

export const App: React.FC = (): JSX.Element => {
  const cssClasses = [Styles.app, import.meta.env.REACT_APP_PLATFORM?.toLowerCase()]

  // When the user needs to get logged in via a short living token (which we call a refreshToken as well in our application),
  // we need to make sure the application isn't going to fire authorized queries before the login process is finished because this causes errors.
  // For now we force a logout by clearing the accessToken which brings the application in an unauthorized state.
  // Finally the Reauthorize page is loaded which handles the login.
  // In a later phase we could try to handle the login higher in the hierarchy then a page.
  forceLogout()

  const hasAccessToken = !!getToken()

  return (
    <div className={cssClasses.join(' ')} id="AppWrapper">
      <Router>
        <AuthProvider isLoggedIn={hasAccessToken}>
          <Wrapper />
        </AuthProvider>
      </Router>
    </div>
  )
}
