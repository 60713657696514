import '#lib/i18n'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { MainLoading } from '#components/MainLoading'
import { MaintenanceNotice } from '#components/MaintenanceNotice'
import { useGetThemeQuery } from '#lib/graphql'
import { getSSOConfig } from '#lib/jwtHelper'

import { setCSSColorVarsForColors } from './helpers'
import { IThemeContext, IThemeProvider, TTheme } from './interfaces'

export const ThemeContext = React.createContext<IThemeContext>({
  currentTheme: {},
  setOverrideHostname: (): void => {},
})

export const ThemeProvider: React.FC<IThemeProvider> = ({ client, children }): JSX.Element | null => {
  const [theme, setTheme] = useState<TTheme>({})
  const [overrideHostname, setOverrideHostname] = useState<string>()
  const ssoConfig = getSSOConfig()

  const getHostName = useCallback((): string => {
    if (overrideHostname) return overrideHostname

    if (ssoConfig?.host) return ssoConfig.host

    return window.location.hostname
  }, [overrideHostname, ssoConfig?.host])

  const updateFavicon = useCallback((uri: string): void => {
    const links = document.querySelectorAll("link[rel~='icon']")

    if (!links.length) {
      const link = document.createElement('link')

      link.rel = 'icon'
      link.href = uri

      document.head.appendChild(link)
    } else {
      links.forEach((link) => {
        const htmlLink = link as HTMLLinkElement
        htmlLink.href = uri
      })
    }
  }, [])

  const { loading, error } = useGetThemeQuery({
    client,
    variables: {
      hostname: getHostName(),
    },
    onCompleted: (data) => {
      if (!data?.configurationProfileForHost?.customerLabelTheme) return

      const { customerLabelTheme } = data.configurationProfileForHost
      setTheme(customerLabelTheme)
      setCSSColorVarsForColors({
        primaryColor: customerLabelTheme?.primaryColor,
        secondaryColor: customerLabelTheme?.secondaryColor,
      })

      if (customerLabelTheme.favicon?.uri) updateFavicon(customerLabelTheme.favicon?.uri)
    },
  })

  useEffect(() => {
    let timeout: number

    if (error) {
      timeout = window.setTimeout((): void => {
        window.location.reload()
      }, 10000)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [error])

  const options = useMemo(() => {
    return {
      currentTheme: theme || {},
      overrideHostname,
      setOverrideHostname,
    }
  }, [overrideHostname, theme])

  if (error) return <MaintenanceNotice error={error} />

  if (loading) return <MainLoading />

  return <ThemeContext.Provider value={options}>{children}</ThemeContext.Provider>
}
