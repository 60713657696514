import React from 'react'

import { Icon } from '#components/Icon'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const PillButton: React.FC<IProps> = ({
  label,
  variant = 'primary',
  size = 24,
  iconBefore,
  className,
  ...otherProps
}): JSX.Element => {
  const sizeClassName = Styles[`size${size}`]

  return (
    /* eslint-disable-next-line reisbalans-rules/prevent-vanilla-html-elements */
    <button
      type="button"
      className={`${Styles.pillButton} ${Styles[variant]} ${sizeClassName} ${className}`}
      {...otherProps}
    >
      {iconBefore && <Icon name={iconBefore} />}

      {label}
    </button>
  )
}
