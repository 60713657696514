// eslint-disable-next-line simple-import-sort/imports
import { scan } from 'react-scan' // must be imported before React and React DOM
// We're required to import the IE11 polyfill first
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'ie11-custom-properties'
// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import './index.scss'
// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register'

import React from 'react'
import { createRoot } from 'react-dom/client'
import Modal from 'react-modal'

import { App } from './App'
import * as serviceWorker from './Lib/serviceWorker'

const { MODE, REACT_APP_SCAN_ENABLED } = import.meta.env
const enableScan = MODE === 'development' && REACT_APP_SCAN_ENABLED === 'true'

scan({ enabled: enableScan })

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
