import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  fromPromise,
  InMemoryCache,
  InMemoryCacheConfig,
  Operation,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import ApolloLinkNetworkStatus from 'react-apollo-network-status/dist/src/ApolloLinkNetworkStatus'

import { arrayMerge, isUnauthorizedError, updateToken } from './apollo/helpers'
import { ICustomResponseObject, MESH_CONTEXT_NAME } from './apollo/interfaces'
import possibleTypes from './apollo/possibleTypes.json'
import { generateApolloClientHeaders } from './headersHelper'
import { getToken } from './jwtHelper'
import { TLocale } from './sharedInterfaces'

const pendingRequests: ((token: string) => void)[] = []
let isRefreshing = false

const requiresMeshEndpoint = (operation: Operation): boolean => operation.getContext().name === MESH_CONTEXT_NAME

// We add the operation name as query parameter so that the VCR functionality can differentiate between consecutive graphql requests.
// As an added bonus, this also allows us to track statistics on how ofter a certain query is called.
const customFetch = (uri: string, options: RequestInit | undefined): Promise<Response> => {
  const { operationName } = JSON.parse((options?.body as string) || '')

  return fetch(`${uri}?operationName=${operationName}`, options)
}

const defeaultEndpointSettings = {
  fetch: customFetch,
  ...{ credentials: 'include' },
}

const baseEndpointLink = createHttpLink({
  uri: import.meta.env.REACT_APP_GRAPHQL_URL_RB as string,
  ...defeaultEndpointSettings,
})

const meshEndpointLink = createHttpLink({
  uri: import.meta.env.REACT_APP_GRAPHQL_URL_MESH as string,
  ...defeaultEndpointSettings,
})

const dynamicApolloLink = ApolloLink.split(requiresMeshEndpoint, meshEndpointLink, baseEndpointLink)

// Generate shared InMemoryCache options to share between this apollo client and mockedprovider
export const sharedInMemoryCacheOptions: InMemoryCacheConfig = {
  ...possibleTypes,
  dataIdFromObject(responseObject: ICustomResponseObject) {
    // eslint-disable-next-line sonarjs/no-small-switch
    let id: string | undefined = 'Places'

    switch (responseObject.__typename) {
      case 'Coordinates':
        return `Cooridantes:${responseObject.latitude}-${responseObject.longitude}`

      case 'EmployeeAddress':
        return `EmployeeAddress:${responseObject.city}-${responseObject.street}-${responseObject.streetNumber}`

      case 'RequestableFeature':
        return `id:${responseObject?.name?.value}`

      case 'Places':
        if (responseObject.suggest?.placeId) id = `suggest_${responseObject.suggest.placeId}`
        if (responseObject.details?.placeId) id = `details_${responseObject.details.placeId}`

        return `Places:${id}`

      default:
        id = defaultDataIdFromObject(responseObject)

        if (!id) {
          if (responseObject.create?.taxiReservation)
            return `TaxiReservation:${responseObject.create.taxiReservation.id || 'UNKNOWN'}`

          if (responseObject.permissions && responseObject.id) return `Permissions:${responseObject.id}`
        }

        return defaultDataIdFromObject(responseObject)
    }
  },
  typePolicies: {
    Employee: {
      fields: {
        mobilityEvents: {
          merge: arrayMerge,
        },
        personalMeansOfRegistrations: {
          // Merge the incoming list items deeply with the existing list items.
          merge: arrayMerge,
        },
        financialConsequences: {
          merge: true,
        },
        permissions: {
          merge: true,
        },
        parkingOrders: {
          merge: false,
        },
        routeMetadata: {
          merge: true,
        },
      },
    },
    DayWithMobilityEvents: {
      keyFields: ['date'],
    },
    LicensePlate: {
      keyFields: ['licensePlate'],
    },
    Timestamp: { merge: true },
    Actions: { merge: true },
    DirectDebitMandates: { merge: true },
    Financial: { merge: true },
    How: { merge: true },
    RouteMetadata: { merge: true },
    HowMuch: { merge: true },
    What: { merge: true },
    When: { merge: true },
    Where: { merge: true },
    Route: { merge: true },
    Location: { merge: true },
    RentalLocation: { merge: true },
    Status: { merge: true },
    Why: { merge: true },
    Taxi: { merge: true }, // This object represents the namespace of taxi mutations
    Vehicle: { merge: false }, // This object represents the namespace of taxi mutations
    PersonalMeansOfRegistrationKind: { merge: true },
    Profile: {
      keyFields: ['employeeNumber'],
    },
    Station: {
      keyFields: ['value'],
    },
    Subordinate: {
      fields: {
        acceptableMobilityEvents: {
          merge: arrayMerge,
        },
      },
    },
  },
}

const unauthorizedLink = (locale: TLocale): ReturnType<typeof setContext> =>
  setContext((_request, { headers }) => {
    return {
      headers: {
        ...headers,
        ...generateApolloClientHeaders({ locale }),
      },
    }
  })

const authorizedLink = (locale: TLocale): ReturnType<typeof setContext> =>
  setContext((_request, { headers }) => {
    const accessToken = getToken()

    return {
      headers: {
        ...headers,
        ...generateApolloClientHeaders({ accessToken, locale }),
      },
    }
  })

const errorLinkAuthorized = (onUnauthorizedError: () => Promise<string>): ApolloLink =>
  onError(({ networkError, graphQLErrors, operation, forward }) => {
    if (isUnauthorizedError(graphQLErrors)) {
      if (isRefreshing) {
        return fromPromise(
          new Promise<string>((resolve) => {
            if (!networkError) pendingRequests.push(resolve)
          })
        ).flatMap((accessToken) => {
          updateToken(operation, accessToken)

          return forward(operation)
        })
      }

      isRefreshing = true

      const onUnauthorizedErrorFinally = (): void => {
        isRefreshing = false
      }

      return fromPromise(onUnauthorizedError().finally(onUnauthorizedErrorFinally))
        .filter((value) => !!value)
        .flatMap((accessToken) => {
          updateToken(operation, accessToken)

          pendingRequests.forEach((callback) => callback(accessToken))
          pendingRequests.length = 0

          return forward(operation)
        })
    }
  })

const errorLinkUnauthorized = (onReAuthorizeError: () => Promise<void>): ApolloLink =>
  onError(({ networkError, graphQLErrors }) => {
    if (isUnauthorizedError(graphQLErrors) && !networkError) onReAuthorizeError()
  })

const generateClient = (
  receivedLink: ReturnType<typeof setContext>,
  link?: ApolloLinkNetworkStatus
): InstanceType<typeof ApolloClient> => {
  return new ApolloClient({
    cache: new InMemoryCache(sharedInMemoryCacheOptions),
    link: link ? link.concat(receivedLink.concat(dynamicApolloLink)) : receivedLink.concat(dynamicApolloLink),
    connectToDevTools: true,
  })
}

export const authorizedClient = (
  link: ApolloLinkNetworkStatus,
  locale: TLocale,
  onUnauthorizedError = (): Promise<string> => Promise.resolve('')
): ApolloClient<unknown> =>
  generateClient(authorizedLink(locale).concat(errorLinkAuthorized(onUnauthorizedError)), link)

export const unauthorizedClient = (
  locale: TLocale,
  onReAuthorizeError = (): Promise<void> => Promise.resolve()
): ApolloClient<unknown> => generateClient(unauthorizedLink(locale).concat(errorLinkUnauthorized(onReAuthorizeError)))
