import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { checkForValidLicencePlate } from '../../../../../Lib/Formatters/licenseFormatter'
import {
  IVehicleFuelTypeEnum,
  useGetFuelTypeByLicensePlateLazyQuery,
  useGetFuelTypesQuery,
} from '../../../../../Lib/graphql'
import { TFuelType } from '../../../../../Pages/Modalities/PersonalVehicleForm/interfaces'
import { MediaQueriesContext } from '../../../../../Providers'
import { CurvedSection } from '../../../../CurvedSection'
import { Icon, Input, Select, Typography } from '../../../../index'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const FuelTypeInputSection: React.FC<IProps> = ({ value, onChange }): JSX.Element | null => {
  const { id, licensePlate, fuelType, label, icon } = value
  const { t } = useTranslation()
  const { isSMScreen } = useContext(MediaQueriesContext)

  const [getFuelType, { data: fuelTypeData, loading: loadingFuelType }] = useGetFuelTypeByLicensePlateLazyQuery()

  const isFuelTypeFieldDisabled = ((): boolean => {
    return !!licensePlate && checkForValidLicencePlate(licensePlate) && !!fuelTypeData?.vehicleInformation?.fuelType
  })()

  const { data, loading } = useGetFuelTypesQuery()
  const fuelTypes: TFuelType[] = data?.fuelTypes || []

  const handleFuelTypeChange = useCallback(
    (newValue: IVehicleFuelTypeEnum): void => {
      onChange({ ...value, fuelType: newValue })
    },
    [onChange, value]
  )

  const handleLicensePlateChange = useCallback(
    (newInput: string): void => {
      onChange({ ...value, licensePlate: newInput })

      if (checkForValidLicencePlate(newInput)) {
        getFuelType({
          variables: { licensePlate: newInput },
          onCompleted: (response) => {
            const type = response.vehicleInformation?.fuelType?.value
            if (!type) return
            onChange({ ...value, fuelType: type, licensePlate: newInput })
          },
        })
      } else onChange({ ...value, licensePlate: newInput })
    },
    [getFuelType, onChange, value]
  )

  const selected = fuelTypes.find((type) => type.value === fuelType)

  if (loading) return null

  return (
    <CurvedSection>
      <div data-testid={`vehicle-${id}`} className={Styles.fuelTypeInputSection}>
        <div className={Styles.top}>
          <Icon name={icon || 'car'} />
          <Typography className={Styles.label} variant="h1">
            {label}
          </Typography>
        </div>

        <div className={Styles.inputs}>
          <Input
            id={`license-plate-${id}`}
            labelCssClasses={[Styles.inputLabel]}
            label={t('License plate')}
            value={value.licensePlate || ''}
            onChange={handleLicensePlateChange}
            isValid={licensePlate ? checkForValidLicencePlate(licensePlate) : false}
            isOptional
          />

          <Select<TFuelType>
            id={`fuel-type-selector-${id}`}
            label={t('Fuel')}
            width={isSMScreen ? '208px' : '100%'}
            ariaLabel={t('Fuel')}
            options={fuelTypes}
            value={selected}
            isLoading={loadingFuelType}
            placeholder=" "
            disabled={isFuelTypeFieldDisabled}
            onChange={(newValue): void => handleFuelTypeChange(newValue.value)}
            getOptionLabel={(option: TFuelType): string => option.humanized}
            isClearable={false}
            required
          />
        </div>
      </div>
    </CurvedSection>
  )
}
